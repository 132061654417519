/* dashboard */
a {
  &:hover {
    text-decoration:none;
  }
}
.nav-tabs {
    .nav-item {
        margin-right: 9px;
        .nav-link{
            background: #e1e2e8;
            color: $black;
            border-top: 5px solid #e1e2e8;
            &.active{
                background-color: #ffffff;
                border-top: 5px solid #00d284;
            }
        }
    }
}

.card {
  border: 0px solid #e4e9f0;
  box-shadow: 0px 0px 11px #8888881a;
}

.outline-0 {
  outline:0px !important;
}
.text-black {
  color:#0d0d0d !important;
}
.font-14{
  font-size:14px;
}
.font-16{
  font-size:16px;
}
// vertical wrapper
.container-scroller {
    display: flex;
  }
  .page-body-wrapper {
    min-height: calc(100vh - #{$navbar-height});
    @include display-flex();
    @include flex-direction(row);
    padding-left:65px;
    padding-right: 0;
    @media (min-width: 992px){
      width: calc(100%);
    }

    &.full-page-wrapper {
      width: 100%;
      min-height: 100vh;
    }
  }

  .main-panel {
    transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
    width: 100%;
    min-height: calc(100vh - #{$navbar-height});
    padding-top: $navbar-height;
    padding-bottom:30px;
    @include display-flex();
    @include flex-direction(column);
    @media (max-width: 991px) {
      margin-left: 0;
      width: 100%;
    }
    @media (min-width: 992px) and (max-width:1300px) {
      width: 91%;
    }
  }

  .content-wrapper {
    background:#fff;
    padding:24px 24px;
    padding-bottom: 0;
    width: 100%;
    min-height: 550px;
    @include flex-grow(1);
    .card {
      box-shadow: 0px 0px 11px #e1e5ff !important;
    }
  }

  #resizable-grid{
    .drag-item{
      position: relative;
      .dismiss{
        position: absolute;
        top: 7px;
        right: 25px;
        font-size: 15px;
        font-weight: bold;
        color: lighten(theme-color(danger), 10%);
        background: lighten(color(gray-light), 40%);
        height: 15px;
        width: 16px;
        line-height: 13px;
        cursor: pointer;
        text-align: center;
        @include border-radius(100%);
      }
    }
  }

select.form-control {
  &.select-custom {
    height: auto;
    width: auto;
    border-radius: 0.25rem;
  }
}

//accordion css

#accordion {
  .card {
    .card-header {
      padding: 0;
      a {
        padding: 1rem 1.25rem 1rem 0;
        color:$black !important;
        font-weight:500;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 25px;
          color: #959595;
        }
        &.collapsed {
          i {
            transform: rotateX(180deg);
          }
        }
      }
    }
    .card-body {
      padding: 1.25rem 0;
      color: #959595;
    }
  }
}

/*model*/
.modal {
  .modal-dialog {
    .modal-content {
      background-color: #fff;
      .modal-header {
        padding:1rem 1.3rem;
        span{
          font-size: 18px;
        }
        .close {
          margin: -15px -18px -25px auto;
        }
      }
      .modal-body {
        padding:1.5rem 1.3rem;
      }
      .modal-footer {
        padding:0.5rem 1rem;
      }
      .table-diamond-detail {
        tr {
          td, th{
            padding: 12px 15px;
          }
        }
      }
    }
  }
}

// Tree view dropdown style start
.bootstrap-demo .dropdown-trigger {
  border-radius:2px;
  border:1px solid #e4e9f0 !important;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}
.bootstrap-demo .dropdown .dropdown-content{z-index: 9 !important;}
.bootstrap-demo .dropdown .dropdown-content .radio-item{display:none}
.bootstrap-demo {
  .dropdown {
    ul{
      li.tag-item{
        span.tag {
          padding:3px 0px 2px 5px;
        }
        .search {
          border-bottom:0px solid #e4e9f0;
          font-size: 13px;
        }
      }
    }
    .dropdown-content{
      padding:6px;
       ul {
         .infinite-scroll-component  {
          max-height: 300px;
          &::-webkit-scrollbar {
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            background: #eee;
          }
           li{
             &.node {
              // padding: 6px !important;
              display: flex;
              align-items: baseline;
              label {
                margin-bottom: 0;
              }
             }
           }
         }
       }
    }
    .dropdown-trigger{
      &.arrow{
        &:after {
          color:#bebcc1 !important;
          font-size: 12px;
        }
      }
    }
  }
}
// Tree view dropdown style end
.shadow-none{
  box-shadow: none !important;
}
.fs-14{
  font-size: 14px !important;
}
.fs-16{
  font-size: 16px !important;
}
.border-none{
  border: none !important;
}
.font-dark{
  color: #333 !important;
}
.width400{
  width: 400px;
}
.lableNone{
  background: none;
}
.labelGray{
  background-color: #ccc;
}

//Modal Popup Close Button Style Start
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
}
//Modal Popup Close Button Style End

// address details start
.address-details {
   h4 {
     margin-bottom:15px;
     a {

     }
   }
   p {
      margin-bottom:0px;
   }
   a {

   }
}
// address details end

.note-btn{
  padding: 5px 10px;
  i{
    font-size: 14px;
  }
}
.productUploadBox{
  height: auto;
  width: auto;
  vertical-align: top;
  img{
    cursor: pointer;
  }
}
.photo-uploaded{
  margin-right: 10px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 5px;
  select{
    margin-top: 10px;
  }
}
// Nestable css start
.nestable {
  .nestable-list{
    .nestable-item{
      .nestable-item-name{
       font-size: 15px;
        img {
          width:27px;
          margin-right:5px;
        }
        .menu-icon{
          padding: 2px 0px 2px 30px;
          position: relative;
          &:before{
            content:'';
            position:absolute;
            background-image:url(https://hjl.s3.eu-west-2.amazonaws.com/assets/images/sprit-icon1.png);
            background-repeat: no-repeat;
            width: 26px;
            height: 26px;
            left: 0px;
            background-position:35px 0px;
          }
          &.nav-{
            padding: 2px 0px 2px 0px;
          }
          &.nav-icon-1-1:before {background-position:-7px -9px;}
          &.nav-icon-1-2:before {background-position:-42px -9px;}
          &.nav-icon-1-3:before {background-position:-76px -9px; }
          &.nav-icon-1-4:before {background-position:-112px -9px;}
          &.nav-icon-1-5:before {background-position:-147px -9px;}
          &.nav-icon-2-1:before {background-position:-8px -49px;}
          &.nav-icon-2-2:before {background-position:-42px -49px;}
          &.nav-icon-2-3:before {background-position:-76px -49px;}
          &.nav-icon-2-4:before {background-position:-112px -49px;}
          &.nav-icon-2-5:before {background-position:-147px -49px;}
          &.nav-icon-3-1:before {background-position:-9px -87px;}
          &.nav-icon-3-2:before {background-position:-42px -86px;}

          &.nav-icon-4-1:before {background-position:-8px -131px;width: 45px;}
          &.nav-icon-4-2:before {background-position:-67px -131px;width: 45px; }
          &.nav-icon-4-3:before {background-position:-125px -131px;width: 45px; }
          &.nav-icon-5-1:before {background-position:-8px -164px;width: 45px;  }
          &.nav-icon-5-2:before {background-position:-67px -164px;width: 45px; } 
          &.nav-icon-5-3:before {background-position:-125px -164px;width: 45px; }
          &.nav-icon-4-1, &.nav-icon-4-2, &.nav-icon-4-3, &.nav-icon-5-1, &.nav-icon-5-2, &.nav-icon-5-3{
            padding: 2px 0px 2px 50px;
            background-size: 41px;
          }

          &.nav-icon-6-1:before {background-position:-8px -211px; }
          &.nav-icon-6-2:before {background-position:-42px -211px; }
          &.nav-icon-6-3:before {background-position:-76px -211px; }
          &.nav-icon-6-4:before {background-position:-111px -211px; }
          &.nav-icon-7-1:before {background-position:-9px -252px; }
          &.nav-icon-7-2:before {background-position:-43px -252px;}
          &.nav-icon-7-3:before {background-position:-76px -252px;}
          &.nav-icon-7-4:before {background-position:-109px -252px; width: 35px; } 
        }
      }
    }
 }
} 

// Nestable css end
  

.menu-svg-icon {
  margin-top:70px;
  svg {
    height:100px;
    width:100px;
  }
}


// Tooltip for menu icon info
.bs-tooltip-top  {
  .tooltip-inner {
    max-width:255px;
    color: #333; 
    background-color: #e1e2e8;
    padding: 10px;
  }
  .arrow {
    &::before {
      border-top-color: #e1e2e8;
    }
  }
}

.bs-tooltip-bottom  {
  .tooltip-inner {
    max-width:255px;
    color: #333; 
    background-color: #e1e2e8;
    padding: 10px;
  }
  .arrow {
    &::before {
      border-bottom-color: #e1e2e8;
    }
  }
}

/* filter bar start */
.filter-bar {
  position:relative;
  display:none;
   &.filter-show {
     display:block;
   }
   .card{
     &:before {
       position: absolute;
       content: '';
       border: 15px solid transparent;
       border-bottom-color:#ededed;
       top: -31px;
       right: 70px;
       width: 25px;
       height: 25px;
    }
    &:after {
     position: absolute;
     content: '';
     border: 14px solid transparent;
     border-bottom-color: #fff;
     top: -28px;
     right: 70px;
     width: 25px;
     height: 25px;
    }
   }
   .form-control{
     height:40px;
     padding: 7px 12px;
   }
   .active-filter-box {
     .filter-result-list {
       span {
         display: inline-block;
         font-size: 14px;
         &.filter-result-repeat {
           margin:0px 0px 5px 10px;
           a{
             color:#333;
             display:inline-flex;
             align-items: center;
             background: #f1f1f1;
             padding: 2px 6px 2px 10px;
             font-size: 13px;
             border-radius: 34px;
             .btn-close{
               background-size:9px;
             }
           }
         }
      }
     }
   }
   .filter-result-repeat-title{
     color:#666;
   }
}
/* filter bar end */

/* spinner css*/
.spinner-loader{
  position: fixed;
  right: auto;
  left:0px;
  transform: translate(0%, 0%);
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
      position: relative;
      width:100px;
  }
  &:before {
      content:'';
      width:100%;
      height:100%;
      background:$white;
      opacity:0.7;
      position: absolute;
      transform: translate(0%, 0%);
      top:0px;
      left:0px;
  }
}

.cursor{
  cursor:pointer;
}
iframe{
  display: none;
}