/* Buttons */

.btn {
  font-size: $btn-font-size;
  line-height: 1;
  font-family: $type-1;
  font-weight: $font-weight-semibold;
  @media (max-width: 576px) {
    font-size: 9px;
  }
  i {
    font-size: 20px;
  }
  &.btn-rounded {
    @include border-radius(50px);
  }
  &.btn-fw {
    min-width: $button-fixed-width;
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
  }
  &.btn-lg {
    font-size: $btn-font-size-lg;
    @media (max-width: 576px) {
      font-size: 9px;
      padding: 12px 8px;
    }
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
  }
  /* Buttons with only icons */
  &.btn-icon {
    width: 42px;
    height: 42px;
    padding: 0;
  }
  /* Buttons with icon and text */
  &.btn-icon-text {
    .btn-icon-prepend {
      margin-right: .5rem;
    }
    .btn-icon-append {
      margin-left: .5rem;
    }
  }
  &.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0;
  }
}

 
.btn{
  box-shadow: 0px 0px 0px 0px #999 !important;
  padding: 12px 15px;
  font-size: 14px;
  font-weight:$font-weight-regular;
  &:hover {
    background-color:#e2e6ea;
  }
  &.btn-dark {
    background-color:$black;
    &:hover{
      background-color:#282828;
    }
  }
  &.btn-primary {
    &:hover {
      background-color: #d6d6db;
    }
  }
  &.btn-light {
    &:hover {
      background-color:#e2e6ea;
    }
  }
  &.btn-secondary {
    &:hover {
      color: #030303;
      background-color:#a0a0a0;
    }
  }
  &.btn-success {
    &:hover {
      background-color:#00bb75;
    }
  }
  &.btn-info {
    &:hover {
      background-color:#00bfe1;
    }
  }
  &.btn-warning {
    background-color:#ffc107;
    &:hover {
      background-color:#f1b605;
    }
  }
  &.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    &:hover {
      background-color:#d12d3c;
    }
  }
}

.btn-group {
  .btn {
    + .btn {
       
    }
    @media(max-width: 991px) {
      padding: 8px 7px;
    }
  }
}

.btn-toolbar {
  .btn-group {
    +.btn-group {
      @extend .ml-2;
    }
  }
} 