/* login form css */
.login-page {
  display: flex;
  align-items: center;
  height: 100vh;
  //background: url("/assests/images/login-dark-bg.jpg") left top no-repeat;
  //background: url("../../../public/assests/images/login-dark-bg.jpg") left top no-repeat;
  padding: 50px 0px;
  color: #757575;
  background-size: 43% 100%;
  @media (max-width: 767px) {
    padding: 15px 0px;
  }
  @media (max-width: 991px) {
    height: auto;
    background-size: 100% 100%;
  }
  @media screen and (min-width: 1025px) and (max-width: 1439px) {
    background-size: 43.5% 100%;
  }
  @media (min-width: 1600px) {
    background-size: 45% 100%;
  }
  a {
    color: #757575;
  }
  .login-box {
    background: #ffffff08;
    box-shadow: 0px 0px 21px #0d0d0d66;
    border-radius: 10px;
    overflow: hidden;
  }
  .login-logo {
    margin: 15px 0px;
    @media (max-width: 767px) {
      margin: 25px 0px;
    }
    img {
      max-width: 305px;
      @media (max-width: 767px) {
        max-width: 145px !important;
      }
      @media (max-width: 991px) {
        max-width: 200px;
      }
    }
  }
  .login-form {
    padding: 50px;
    @media (max-width: 991px) {
      padding: 20px;
    }
    @media screen and (min-width: 992px) and (max-width: 1024px) {
      padding: 25px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1439px) {
      padding: 30px 50px;
    }
    .logo-name-text {
      text-align: center;
      margin: 0px auto;
      display: block;
      color: #000;
      padding: 15px 69px 0px;
      font-family: "Cinzel", serif !important;
      font-weight: 500;
      font-size: 42px;

      @media (max-width: 767px) {
        padding: 15px 10px 0px;
        font-size: 23px !important;
      }
      @media (max-width: 991px) {
        padding: 15px 15px 0px;
        font-size: 29px;
      }
      @media screen and (min-width: 992px) and (max-width: 1024px) {
        font-size: 35px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1439px) {
        font-size: 38px;
        padding: 15px 0px 0px;
      }
      img {
        @media (max-width: 767px) {
          max-width: 380px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1439px) {
          max-width: 380px;
        }
      }
    }
    .login-sub-title {
      padding: 45px 0px;
      margin: 0px;
      @media (max-width: 991px) {
        padding: 25px 0px;
      }
      @media screen and (min-width: 992px) and (max-width: 1024px) {
        padding: 30px 0px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1439px) {
        padding: 25px 0px;
      }
    }
    .login-form-box {
      padding: 60px 60px 60px;
      @media (max-width: 991px) {
        padding: 20px 10px 40px;
      }
      @media screen and (min-width: 992px) and (max-width: 1024px) {
        padding: 40px 60px 40px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1439px) {
        padding: 40px 35px 40px;
      }
      .form-control {
        border: 0px;
        border-bottom: 1px solid #ddd;
        border-radius: 0px;
        min-height: 45px;
        background: #fff;
        &:focus {
          box-shadow: 0 0 0 0rem #333;
        }
        ::-webkit-input-placeholder {
          /* Edge */
          color: #000;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #000;
        }
        ::placeholder {
          color: #000;
        }
      }
      .input-group-text {
        border: 0px;
        border-bottom: 1px solid #ddd;
        border-radius: 0px;
        background: transparent;
        padding: 0.375rem 0rem;
        img {
          width: 18px;
        }
      }
      .form-check-input {
        margin-top: 0.2rem;
        margin-left: 0;
      }
      .login-btn {
        background: #1b1b1a;
        padding: 1rem 0.75rem;
        border-radius: 0.25rem !important;
        margin-top: 40px;
        font-size: 15px;

        @media (max-width: 991px) {
          margin-top: 15px;
        }
        @media screen and (min-width: 992px) and (max-width: 1024px) {
          margin-top: 30px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1439px) {
          margin-top: 20px;
        }
      }
    }
  }
}

.form-inline {
  display: flex;
  align-items: center;
}

.input-group {
  .text-danger {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
  }
}
