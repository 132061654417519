/* Tables */

.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type-1;
      font-weight: $font-weight-semibold; 

      i {
        margin-left: 0.325rem;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap; 

    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }

    .badge {
      margin-bottom: 0;
    }
  }
  tbody {
    td {
      color:$gray-light; 
    }
  }

  &.table-borderless {
    border: none;

    tr,
    td,
    th {
      border: none;
    }
  }
  &.apointment-table {
    td {
      img {
        width: 20px;
        height:20px;
      }
    }
  }
}
.custom-table {
  thead {
    th {
      font-weight: $font-weight-bold;
      font-size: 14px;
      background: $thead-bg;
      &:first-child {
        padding-left: 28px;
        .rtl & {
          padding-right: 28px;
        }
      }
    }
    tr {
      th {
        border: 0;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;
    font-weight: $font-weight-semibold;
    padding: 12px;

    img {
      width: 23px;
      height: 23px;
      border-radius: 0;
    }
  }
  td:first-child {
    padding-left: 28px;
    .rtl & {
      padding-right: 28px;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $border-color;
      &:first-child {
      td {
        border: 0;
      }
    }
    }
  }
}

/* data table css*/
.MuiPaper-root{
  .MuiToolbar-root {
    .MuiFormControl-root {
      .MuiInputBase-formControl {
        border: 1px solid #e4e9f0;
        padding: 0px 0px 0px 10px;
        border-radius: 5px;
        font-family:$type-1;
        font-size: 14px;
        min-height: 40px;
        &:before {
          border-bottom:0px solid #eee;
        }
        .material-icons {
          color: #bebcc1;
        }
        &:after{
          border-bottom: 0px solid #eee;
        } 
        .MuiIconButton-label {
          .material-icons {
            font-size: 16px;
          }
        }
      }
    }
    .MuiSelect-root {
      border: 1px solid #e4e9f0;
      border-radius: 5px;
      font-family:$type-1;
    }
    .MuiTypography-caption {
      font-family:$type-1;
      font-size: 14px;
    }
  }
  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root{
        th {
          font-weight:600;
          text-align: center;
          border-bottom: 1px solid #e4e9f0;
          background: #e1e2e8;
          padding: 12px 10px;
          min-width: 155px;
          .MuiButtonBase-root {
            font-family:$type-1;
            .MuiTableSortLabel-icon {
              color: #000;
              font-size: 16px;
            }
            &:hover {
              color: #000; 
              .MuiTableSortLabel-icon {
                opacity:1;
              }
            }
          }
        }
      }
    }
    .MuiTableBody-root {
      tr {
        &:hover {
          color: #212529;
          background-color: #f8f9fb;
        }
        td{
          text-align:center;
          border-bottom: 1px solid #e4e9f0;
          font-size: 14px !important;
          padding: 12px 15px;
          font-family:$type-1;
          .action-btn {
            .btn {
              padding: 5px 5px;
            }
            a {
              padding: 0px 4px;
            }
          }
          img {
            
            width:60px;
            height: 60px; 
          } 
          .product-img {
            border-radius: 100%;
            width:60px;
            height: 60px; 
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px auto;
            img {
              width:60px;
              height: 60px;
            }
          }
        }
      }
    }
    .MuiTableFooter-root {
      .MuiTableRow-root {
        td {
          border-bottom:0px solid #eee;
          float: none !important;
          margin: 0px auto;
          display: table;
          width: auto;
        }
      }
    }
  }
  .MuiToolbar-regular{
    min-height: 40px;
    padding-right: 0px;
    padding-bottom:13px;
  }
  .MuiTableFooter-root {
    .MuiToolbar-regular{ 
      padding-bottom:0px;
      min-height: 30px;
      margin-top: 15px;
      .MuiIconButton-root {
        padding: 5px 12px;
      }
    }
  }
} 

.table-responsive {
  .rdt_TableHead{
    .rdt_TableHeadRow{
      font-weight: 600;
      text-align: center;
      border-bottom: 1px solid #e4e9f0;
      background: #e1e2e8 !important; 
      font-size: 0.875rem;
    }
  }
}