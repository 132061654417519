/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&display=swap');


@font-face {
  font-family: 'Mulish', sans-serif;
  src: url("../fonts/Mulish/Mulish-Light.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish', sans-serif;
  src: url("../fonts/Mulish/Mulish-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight:400;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish', sans-serif;
  src: url("../fonts/Mulish/Mulish-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight:700;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish', sans-serif;
  src: url("../fonts/Mulish/Mulish-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight:600;
  font-style: normal;
}