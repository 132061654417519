.alert { 
    border:0px;
    color: $black;
    font-size: 14px;
    padding: 1.15rem 2.25rem  1.15rem 1.25rem;
    &.alert-primary {
    background-color: #007bff29;
    border-left: 5px solid #007bff;
     .close { 
       color: #007bff;
      }  
    }
    &.alert-secondary {
    background-color: #6c757d29; 
    border-left: 5px solid #6c757d;
      .close { 
        color: #6c757d;
       } 
    }
    &.alert-success {
    background-color: #9abd6529; 
    border-left: 5px solid #9abd65;
      .close { 
        color: #9abd65;
       }

    }
    &.alert-danger {
    background-color:#dc354529; 
    border-left: 5px solid #dc3545;
      .close { 
        color: #dc3545;
       }
    }
    &.alert-warning {
    background-color:#ffc10729; 
    border-left: 5px solid #ffc107;
      .close { 
        color: #ffc107;
       }
    }
    &.alert-info {
    background-color: #17a2b829; 
    border-left: 5px solid #17a2b8;
      .close { 
        color: #17a2b8;
       }
    }
    &.alert-light {
    background-color:#eeeff0;
    border-left: 5px solid #343a40;
      .close { 
        color: #343a40;
       }
    }
    &.alert-dark {
    background-color:#343a4029;
    border-left: 5px solid #343a40;
      .close { 
        color: #343a40;
       }
    }
    .close { 
        background-color: #fff;
        padding:0px 4px 3px;
        opacity: 1;
        border-radius: 25px;
        width:25px;
        height:25px;
        top:16px;
        right: 15px;
        font-weight:400;
        font-size:22px;
        line-height: 22px;
    }
}

/* swal model */
.swal-modal {
  text-align: left;
  will-change: initial !important;
  .swal-title {
    text-align: left;
    margin-top: 10px;
    padding:12px 24px 7px;
    margin-bottom: 0px;
    color:$black;
    font-weight:$font-weight-semibold;
    font-size:24px;
  }
  .swal-text  {
    text-align: left;
    padding: 0 24px;
    color: #000000bd;
    font-size: 14px;
  }
  .swal-footer {
    padding:13px 20px 17px;
    .swal-button {
      box-shadow: 0px 0px 0px 0px #000;
      font-weight:$font-weight-semibold;
      &.swal-button--cancel{
        color:$black;
      }
      &.swal-button--confirm {
        background-color:#9abd65;
        border-color:#9abd65;
      }
    }
  }
}

/* Toastify css */
.Toastify__toast--success {
  background: #9abd65 !important;
}
.Toastify__toast-body {
  font-family:$type-1;
}