/* Icons */

.icons-list {
  border-left: 1px solid $border-color;
  border-top: 1px solid $border-color;
  >div {
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    @include display-flex;
    @include align-items(center);
    padding:15px 15px;
    font-family: $type-1;
    font-weight: $font-weight-regular;
    font-size: $default-font-size;

    i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: $icons-page-color;
    }
  }
}
