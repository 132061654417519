/* Badges */

.badge {
  border-radius:4px;
  font-size:12px;
  font-weight: initial;
  line-height: 1;
  padding: .375rem .5625rem;
  font-family: $type-1;
  font-weight: $font-weight-semibold;
  &.badge-pill {
    border-radius: 10rem;
  }
  &.badge-primary {
    background-color: #007bff29;
    color: #007bff;
  }
  &.badge-secondary {
    background-color: #6c757d29;
    color: #6c757d;
  }
  &.badge-success {
    background-color: #28a74529;
    color: #28a745;
  }
  &.badge-danger {
    background-color:#dc354529;
    color: #dc3545;
  }
  &.badge-warning {
    background-color:#ffc10729;
    color: #ffc107;
  }
  &.badge-info {
    background-color: #17a2b829;
    color: #17a2b8;
  }
  &.badge-light {
    background-color:#eeeff0;
  }
  &.badge-dark {
    background-color:#343a4029;
    color: #343a40;
  }
}
