/* Cards */

.card {
  border: 0;
  background: $card-bg;
  border: 1px solid $border-color;
  box-shadow:0px 0px 11px #8888884d !important;
  .card-body {
    padding:20px;
    + .card-body {
      padding-top: 1rem;
    }
  }
  .card-header {
    background-color:#fff;
    padding: 1.25rem 1.25rem;
    border-bottom:1px solid #e4e9f0;
  }
  .card-title {
    color: $card-title-color;
    margin-bottom: 0.75rem;
    text-transform: capitalize;
    font-family: $type-1;
    font-weight: $font-weight-bold; 
  }
  .card-subtitle {
    @extend .text-gray;
    font-family: $type-1;
    font-weight: $font-weight-regular;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .card-description {
    color: $card-description-color;
    margin-bottom: 1.5rem;
    font-family: $type-1;
    font-weight: $font-weight-regular;
  }
  &.card-outline-success {
    border: 1px solid theme-color("success");
  }
  &.card-outline-primary {
    border: 1px solid theme-color("primary");
  }
  &.card-outline-warning {
    border: 1px solid theme-color("warning");
  }
  &.card-outline-danger {
    border: 1px solid theme-color("danger");
  }
  &.card-rounded {
    @include border-radius(5px);
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }

  &.card-no-shadow {
    box-shadow: none;
  }
}
