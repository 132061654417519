/* Typography */

body {
  font-size: 1rem;
  font-family: $type-1;
	font-weight: $font-weight-regular;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  color:$black;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $type-1;
	font-weight: $font-weight-semibold;
}
p {
  font-size: $default-font-size;
}
h1,
.h1 {
  font-size: 2.19rem;
}
h2,
.h2 {
  font-size: 1.88rem;
} 
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 1rem;
}
h6, 
.h6 {
  font-size: .9375rem;
}
p {
  font-size: 14px;
  line-height: 1.5;
}
.display-1 {
  font-size: 3.75rem;
  @media (max-width: 991px) {
    font-size: 3rem;
  }
}
.display-2 {
  font-size: 3.125rem;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}
.display-3 {
  font-size: 2.5rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}
.display-4 {
  font-size: 1.875rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}
.display-5 {
  font-size: 1.25rem;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}
.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}

//blockqoute color variations
 
.error-page {
  h1 {
    font-size: 12rem;
    @media (max-width: 991px) {
      font-size: 8rem;
    }
  }
}
.icon-lg {
  font-size: 2.5rem;
}
.icon-md {
  font-size: 1.875rem;
}
.icon-sm {
  font-size: 1rem;
}
.text-gray {
  color:$gray-light !important;
}
.text-danger {
  color: red !important; 
}

//error code css
.error {
  font-size:12px;
  margin-top:5px;
  color: red; 
} 

.text-muted {
  color:$black !important;
}
.text-primary-blue {
  color:#007bff;
}