/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background:#fff;
  font-family: $type-1;
  font-weight: $font-weight-regular;
  padding: 0;
  width: $sidebar-width-lg;
  z-index:11;
  position: absolute;
  box-shadow:5px 0px 14px #9999991c; 
  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom:90px;
    width: inherit;

    .nav-item-head {
      padding: 0 $sidebar-menu-padding-x;
      font-size: 11px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .nav-item {
      padding: 0 15px;
      margin-bottom:5px;
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse ,.collapsing {
        z-index: 999;
        &.side-submenu  {
          position: absolute;
          left:240px;
          top:60px;
          background: #e1e2e8;
          height: 100%;
          min-width: 15em;
          padding: 15px;
          opacity:1;
          visibility:visible;
          h4 {
            padding: 0rem 1rem;
            margin-bottom: 20px;
          }
          h5 {
            padding: 0rem 1rem;
            font-size:14px;
          }
        }  
      }
      &.active {
        svg {
          stroke:#000 !important;
        }
        a.nav-link {
          background: #e1e2e8;
          border-radius: 5px;
          color: #000;
          .menu-title {
            font-weight: 500;
            color: #000;
          }
        }
        .sub-menu {
          li {
            a.nav-link{
              background:transparent;
              color: #878787;
              svg {
                stroke: #878787 !important;
              }
              &:hover {
                background: #e1e2e8;
                border-radius: 5px;
                color: #000;
                svg {
                  stroke:#000 !important;
                }
              }
            }
            &.active{
              a.nav-link{
                background: #e1e2e8;
                border-radius: 5px;
                color: #000; 
             }
            }
          }
        }
      }
      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding:12px 10px;
        color:#878787;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        cursor: pointer;
        &:hover {
          background: #e1e2e8;
          border-radius: 5px;
          svg { 
            stroke:$black;
          }
          .menu-title {
            color:$black;
            font-weight:500;
          }
        }
        svg {
          margin-right:12px;
          stroke:#878787;
          width: 20px;
          stroke-width: 1.3px;
        }
        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1.2;
            margin-right: 10px;
            .rtl & {
              margin-right: 0;
              margin-left: 10px;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }

          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: 16px;
            margin-left: auto;
            color: $sidebar-light-menu-arrow-color;
            opacity: 0.36;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            &:before {
              content: "\f142";
              font-size: inherit;
              color: inherit;
              .rtl & {
                content: "\f141";
              }
            }
            + .menu-icon {
              margin-left: 0.25rem;
              .rtl & {
                margin-left: 0;
                margin-right: 0.25rem;
              }
            }
          }
        }
         
        small {
          display: block;
        }

        .menu-title {
          color:#878787;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          white-space: normal;
        }

        .badge {
          margin-right: auto;
          margin-left: 1rem;
        }
        .sidebar-brand-logo  {
          width:140px;
        }
        .sidebar-brand-logomini {
          display: none;
          width: 100%;
        }

        &[aria-expanded="true"] {
          .menu-arrow {
            &:before {
              content: "\f140";
            }
          }
        }
      }

      form {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              padding-left: 16px;
              height: 30px;
              background: #f7f8f9;
              color: #8e94a9;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              .rtl & {
                border-radius: 0;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
              }
            }
          }
          .form-control {
            font-size: 10px;
            padding: 0;
            height: 30px;
            background: #f7f8f9;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            &::placeholder {
              color: #a0a0a0;
            } 
          }
        }
      } 
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          display: block;
          padding: 27px 0 0 0;
          .nav-profile-image {
            width: 65px;
            height: 65px;
            margin-right:10px;
            img {
              width: 65px;
              height: 65px;
              border-radius: 100%;
            }
          }
          .nav-profile-text {
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
        }
      }
    }

    &.sub-menu {
      margin-bottom:0px;
      margin-left:15px;
      margin-top:5px;
      list-style: none;
      border-left:0px solid $border-color;
      flex-direction: initial;  
      .sidebar-icon-only & { 
        padding: 0;
      }

      .nav-item {
        padding: 0;
        min-width: 15em;
        .nav-link {
          color:#878787;
          padding:0.75rem 1rem 0.75rem 1rem; 
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          font-size: 14px; 
          border-radius: 5px;
          &.active {
            color: $sidebar-light-menu-active-color;
            font-weight: $font-weight-semibold;
            background:#e1e2e8;
          }
          &:hover {
            color: $sidebar-light-submenu-hover-color;
            background-color:#e1e2e8;
          }
          svg {
            width: 18px;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
} 

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    background-color:#fff;
    &.active {
      right: 0;
    }
  }
}
